import React, { useState, useEffect, useRef } from "react";
import "./body.css";
import 'boxicons/css/boxicons.min.css';
import { Carousel, initTWE } from 'tw-elements';
import Liran from "../assets/liran.png";
import Hen from "../assets/hen.png";
import video1 from "../assets/vieo1.mp4";
import video2 from "../assets/vieo2 (1).mp4";
import video3 from "../assets/vieo3.mp4";
import W1 from "../assets/w1.jpg";
import W2 from "../assets/w2.jpg";
import W3 from "../assets/w3.jpg";
import W4 from "../assets/w4.jpg";
import W5 from "../assets/w5.jpg";

initTWE({ Carousel });

export default function Body(){
 
  const [currentVideo, setCurrentVideo] = useState<number>(0);
  const videoRefs = [
    useRef<HTMLVideoElement>(null),
    useRef<HTMLVideoElement>(null),
    useRef<HTMLVideoElement>(null),
  ];

  const videos = [video3, video1, video2];

  const handleVideoChange = (index: number) => {
    if (index !== currentVideo) {
      videoRefs[currentVideo]?.current?.pause();
      videoRefs[currentVideo].current!.currentTime = 0;
      setCurrentVideo(index);
    }
  };
  const images = [W5, W2, W3, W4, W1];
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

return(
  
    <>
    <section className="but">
   <h3>אבל...
אנחנו יודעים שיש מחסומים שאתה צריך לפצח בדרך
</h3>
<p><i className='bx bx-check bx-tada' ></i>להיות <span>מנהיג</span> - ולא עריץ </p>
<p><i className='bx bx-check bx-tada' ></i>להיות <span>נחרץ</span> - ולא אכזר</p>
<p><i className='bx bx-check bx-tada' ></i>להטיל <span>סמכות</span> -אבל גם לקבל <span>אחריות</span></p>
<p>יש לך המון תוכניות איך לקדם את החיילים והצבא
אבל אתה חושש לאבד את עצמך
</p>
<h4>אבל זה לא חייב להיות ככה.</h4>
<p>הקמנו במיוחד בשבילך את תוכנית הליווי...</p>
<h4 className="command4">מפקדים לעתיד</h4>
<p>תוכנית ליווי מהפכנית - חוויה מתמשכת הממוקדת רק בך!</p>
<a href="#formd">
    <div className="button-container">
        <button><span>כן אני בפנים!</span></button>
    </div>
</a>
<p>
התוכנית היחידה שמראה לך איך לפתח מנהיגות ולטפס בצמרת הפיקוד בצבא, <br />
להיות מפקד נערץ ששמו הולך לפניו, <br />
מודל וחיקוי לחייליו,

</p>
</section>
<section className="fork">
    <h4>הנה על קצה המזלג חשיפה למה שאנחנו הולכים לעבור בתוכנית ליווי :</h4>
    <div className="opposite">
        <p><i className='bx bx-library bx-flashing' ></i>8 שיעורים  <span>מותאמים אישית</span> – שיעור כל שבוע – כל שיעור 45-60 דקות </p>
        <p><i className='bx bxs-brightness bx-spin' ></i><span>מחולשה לחוזקה</span> -זיהוי נקודות חולשה שכולם מתמודדים ודרכי התמודדות עליהן </p>
    </div>
    <div className="opposite1">
    <p className="attention"><i className='bx bx-male-female bx-tada ' ></i>יחס <span>אישי</span> ומקצועי</p>
    <p><i className='bx bx-time bx-tada' ></i>ייעוץ <span>בזמן אמת</span> וזמינות גבוהה לכל שאלה בקשה והתייעצות, עזרה ברגעים קריטיים ומענה נכון ומדויק עבורך</p>
    </div>
    <p className="bottomfork"><i className='bx bx-library bx-flashing' ></i>התוכנית אורכת 60 יום והיא ממוקדת <span>להצלחה האישית</span> של כל מפקד או מפקדת</p>
<p className="all">וזה לא הכל...</p>
<h4 className="promise">ההבטחה שלנו היא...</h4>
<p >לגרום לכך שלא תהיה מטרה שלא תהפוך להיות ראליות להשגת יכולות פיקודיות ומנהיגותיות </p>
<p className="prom">
אתה תפתח מנהיגות, <br />
סמכותיות, <br />
כוח הנעה ושכנוע, <br /> 
דרך הלמידה והתרגול תקבל סל של כלים פרקטיים שיזניקו אותך מול הסביבה הצבאית שלך

</p>
<p >
לא עוד יחס מזלזל מצד הסביבה <br />
 יכולת הנעה לפעולה <br />
הסוף להעדר מנהיגות <br />
די לתסכול, למרמור ולחוסר הבנת החיילים הבעייתיים
</p>
</section>
<section className="love" >
    <div className="dad">
        <div>
          <div className="pipsh">
            <h4>עם יד על הלב, עד כמה זה <span>שווה</span> לך?</h4></div>
            <p><i className='bx bxs-offer bx-tada' ></i></p>

            <div className="pips">
            <p>לא לראות את המפקדים מקדמים את חבריך ואותך משאירים מאחור! <br />
לא להיות כל שבוע באותו מרוץ העכברים, לרדוף אחרי אותם חיילים, ולצעוק את אותם הצעקות, <br />
להפסיק לספור את הימים עד לשחרור
</p></div>
        </div>
        <div><div className="pipsh">
        <h4> אחרי התוכנית <span>ליווי</span> אצלנו...</h4></div>
        <p><i className='bx bx-smile bx-spin' ></i></p>
        <div className="pips">
        <p>
        אתה תרגיש סיפוק והנאה בתפקיד שלך <br />

אתה תשאיר חותם חיובי מעצים אצל החיילים שלך

        </p>
        </div>
        </div>
       
    </div>
</section>
<section className="us">
<h4>למה להאמין לנו?</h4>
    <p>
אז נעים להכיר... אנחנו הפנים מאחורי תוכנית הליווי המהפכנית ״מפקדים לעתיד״
</p>


<div className="about">
    <div>
        <h3>לירן רז</h3>
        <img src={Liran} alt="לירן רז" />
        <p>שירתתי כלוחם ומפקד בגדוד 869
במספר תפקידי מפתח,
המשכתי לקבע כמפקד מגמת הכשרות
הלוחמים באיסוף קרבי בבסיס האימונים
סיירים ופיקדתי על מדריכות איסוף קרבי
  </p>
    </div>
    <div>
    <h3>חן דגן</h3>
        <img src={Hen} alt="חן דגן" className="img1" />
        <p>שירתתי כמאבחנת מדעי התנהגות במכללה הצבאית למד״ה, בנוסף לקחתי חלק במספר גיבושים קרביים ובמרכז הערכה אל״ם. נבחרתי כמשפיעה תחת קטגוריית חיילות בעיריית אור יהודה ביום האישה הבינלאומי</p>
    </div>
</div>
<p className="aboutbottom">
 פיתחנו את תוכנית מפקדים לעתיד, מתוך רצון לעזור לכל מפקד להשפיע! <br />
 בכל תהליך הבנייה חשבנו על הדרך שעברנו כחיילים ובפרט בדרך ובקשיים שלירן עבר כמפקד ובהיכרות של חן בעולם משאבי האנוש ומדעי ההתנהגות מהצבא. <br />
התוכנית מיועדת לכל מפקד שרוצה להיות <span>מפקד מצטיין</span> ומוכן לתהליך שמעניק כלים פרקטיים, סימולציות פיקודיות וליווי אישי בדרך

</p>


</section>
<section className="video-carousel-container">
        <div className="video-container">
          {videos.map((video, index) => (
            <video
              key={index}
              ref={videoRefs[index]}
              src={video}
              controls={index === currentVideo}
              className={`video ${index === currentVideo ? "active" : ""}`}
            />
          ))}
        </div>
        <div className="carousel-buttons">
          {videos.map((_, index) => (
            <button
              key={index}
              onClick={() => handleVideoChange(index)}
              className={`carousel-button ${index === currentVideo ? "active" : ""}`}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </section>
    
      <div className="carousel-container">
      <img src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} className="carousel-image" />
      <button className="carousel-button prev" onClick={handlePrev}>‹</button>
      <button className="carousel-button next" onClick={handleNext}>›</button>
      <div className="carousel-indicators">
        {images.map((_, index) => (
          <div
            key={index}
            className={`carousel-indicator ${index === currentIndex ? 'active' : ''}`}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </div>
    </div>
    </>
)

}