import React from "react";
import "./footer.css";
import Logo from "../logo.jpg";

export default function Footer() {
  return (
    
    <>
      <section className="end">
        <p>
          <span>ביחד אנחנו מתחייבים לפיתוח המקצועי שלך</span>
          <br />
          ביחד נגרום לך לראות את המערכת הצבאית בעין שונה טובה ומהנה
          <br />
          נלמד להשתמש בקלפים ״הרעים״ שאתה מקבל בתפקיד <br />
          בתהליך שאורך רק 60 יום!
        </p>
        <div className="formd" id="formd">
          <h3>צור קשר היום!</h3>
          <p>
            מלא את הטופס למטה ונקבע פגישה ראשונית חינם ללא עלות וללא התחייבות
            <div className="br"></div> בוא נתחיל את השינוי שלך יחד!
          </p>
          {/* Custom HTML Form */}
          <form
            action="https://docs.google.com/forms/d/e/1FAIpQLSeRm6dlsvonPpoZklnMJ_eMsaKvG-oW46lJPM2D5XQiTBCLeg/formResponse"
            method="POST"
            target="_blank"
          >
            <input
              type="text"
              name="entry.1913056283"
              placeholder="שם מלא:"
              required
            />
            <input
              type="tel"
              name="entry.632514488"
              placeholder="מספר פלאפון:"
              required
            />
            <input
              type="email"
              name="entry.1594155153"
              placeholder="מייל:"
              required
            />
            <button type="submit">
              <span>לירן וחן אני בפנים!</span>
            </button>
          </form>
        </div>
      </section>
      <footer>
        <div className="home-sci">
          <a href="https://www.youtube.com/@pikud_il" target="_blank" >
            <i className="bx bxl-youtube"></i>
          </a>
          <a href="https://www.instagram.com/pikud_il?igsh=bjhsbHg1OXVrZWV4&utm_source=qr" target="_blank">
            <i className="bx bxl-instagram"></i>
          </a>
          {/* WhatsApp Icon */}
          <a
            href="https://wa.me/+972507003086"
            className="whatsapp_float"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="bx bxl-whatsapp"></i>
          </a>
          <a href="https://www.tiktok.com/@pikud_il?_t=8pBflUrT48g&_r=1" target="_blank">
            <i className="bx bxl-tiktok"></i>
          </a>
        </div>
        <img src={Logo} alt="מפקדים לעתיד" />
      </footer>
      <div className="idan"><a href="https://idanbakal.co.il/" target="_blank" ><span>.All rights reserved to Idan Bakal ©</span></a></div>
    </>
  );
}
