import React from "react";
import "./header.css";
import Logo from "../assets/logo-removebg.png"; // Use default import syntax
import Lihen from "../assets/lihen.png";
import Body from "../body/body";
import Footer from "../assets/footer/footer";

{/* <img src={Logo} alt="Logo" /> {/* Fixed JSX syntax */}  

export default function Header() {
    return (
        <>
        <header>
        <div className="backg">
            <img src={Logo} alt="מפקדים לעתיד" className="logo"/>
            
            
                <h1>למפקד שרוצה באמת לפקד                             </h1>
                <h2>סוף סוף אתה הולך ללמוד את כללי המשחק בצמרת הפיקוד</h2></div>
                <p>"לא אכפת לי כמה אתה יודע, אני רוצה לדעת כמה אכפת לך"</p>
                <img src={Lihen} alt="לירן וחן" />

              <div className="pips">  <p className="p1">
                    עד סוף העמוד הזה אנחנו מבטיחים לחשוף לפניך את <span>7</span> הכללים שאתה חייב לסגל לעצמך
                </p></div>
                <div className="paps">
                <div className="paps1"><i className='bx bx-down-arrow bx-fade-down arrow'  ></i> 
                          <div className="first">  
                        <h3>אתה מפקד, עכשיו הזמן להתקדם!</h3>
                        <p>
                            לא משנה אם זה הצעד הראשון שלך בפיקוד או שאתה נמצא בתחום כבר כמה שנים,
                            העמוד שלפניך הולך לתת לך הצעה שתשנה לך את החיים
                        </p>
                        <a href="#formd">
                            <button> <span>כן אני בפנים!<br /> <i className='bx bxl-sketch bx-tada'></i></span></button>
                        </a>
                    </div></div>
                    <div className="paps1"><i className='bx bx-down-arrow bx-fade-down arrow'  ></i>  
                    <div className="first1">
                        <h3>תוכנית הליווי למפקדים - השדרוג שאתה חייב לסגל לעצמך!</h3>
                        <p>
                            תענה בכנות - האם אתה באמת מפקד שמנצל את יכולותיך? <br />
                            עד כמה אתה בטוח שהנך יכול להנחיל סמכות בסביבתך? <br />
                            יש בך מספיק אומץ ורצון לכבוש את לבבות חיילך?
                        </p>
                        <a href="#formd">
                            <button> <span>כן אני בפנים!<br /> <i className='bx bx-dumbbell bx-tada'></i></span>
                            </button> 
                        </a>
                        </div>
                    </div>
                    <div className="paps1">
                    <i className='bx bx-down-arrow bx-fade-down arrow'  ></i> 
                    <div className="first">
                        <h3>אנחנו יודעים בדיוק איך אתה מרגיש עכשיו</h3>
                        <p>
                            בוער בך תחושת האחריות,
                            העיניים שלך משדרות מנהיגות,
                            יש בך דאגה ומסירות,
                            כל אלה הביאו אותך עד לכאן
                        </p>
                        <a href="#formd">
                            <button> <span>כן אני בפנים!<br /> <i className='bx bx-male-female bx-flashing'></i></span></button>
                        </a>
                    </div>
                    </div> 
                </div>
            </header>
            <Body />
            <Footer />
        </>
    );
}
