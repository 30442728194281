import React from 'react';
import logo from './logo.svg';
import './App.css';
import Header from './pages/header/header';


function App() {
  return (
  <Header />
  );
}

export default App;
